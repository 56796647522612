import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";

import { Firebase, FieldValue } from "../App";

import {
  getDocument,
  updateDocument,
  useDocument,
} from "./FirestoreService";
import { isLoading } from "../utils/uiUtils";
import { getDefaultCategoriesSettings } from "./SystemEventService";
import { uploadUserImage } from "./StorageService";
import { API_PATHS, postPrivateRequest } from "./ApiService";
import { isUndefined } from "utils/generalUtils";

const COLLECTIONS = {
  USER_INFO: "users",
  ADMINS: "admin_users",
};

const SUBCOLLECTIONS = {
  SETTINGS: "settings",
};

const DOCUMENTS = {
  PROJECT: "projects",
  NEWS: "news",
  ORGANISATIONS: "organisations",
};

const KEYS = {
  ACCOUNT_ID: "accountId",
  COMPANY: "company",
  EMAIL: "email",
  IMAGE: "img",
  JOB_TITLE: "jobTitle",
  NAME: "name",
  PINNED_PROJECTS: "pinnedProjects",
  USERS_LAST_UPDATE: "usersLastUpdate",
  NEWS_CATEGORIES: "newsCategories",
  USER_ID: "usersId",

  LAST_READ: "lastRead",
  NEWS_LAST_READ: "newsLastRead",

  // events
  CATEGORY: "category",
  TIMESTAMP: "timestamp",
};
const DELIMITER = "::";

/**
 * User ID
 */

export const getCurrentUserId = () => Firebase.auth().currentUser?.uid;

// get current user id from profile (firestore)
export const useCurrentUserId = () => {
  const profile = useSelector(
    ({ firebase: { profile } }) => isLoaded(profile) && profile
  );
  if (profile) return profile[KEYS.USER_ID];
  else return undefined;
};

/**
 * User info
 */

export const getUserInfo = (userId) =>
  getDocument({ collection: COLLECTIONS.USER_INFO, doc: userId });

// sets user document data in the User collection
// returns a Promise
export const setUserInfo = async (email, fullname, userId) => {
  email = email.replace(/\./g, "~");
  return updateDocument({
    collection: COLLECTIONS.USER_INFO,
    doc: userId,
    data: { email, name: fullname, accountId: email, usersId: userId },
  });
};

export const useUserInfo = (userId) =>
  useDocument({
    collection: COLLECTIONS.USER_INFO,
    storeAs: `${COLLECTIONS.ORGANISATION_INFO}${DELIMITER}${userId}`,
    doc: userId,
  });

export const getUserName = async (userId) => {
  const info = await getUserInfo(userId);
  return info && info.name;
};

export const useUserName = (userId) => {
  const info = useUserInfo(userId);
  return info && info.name;
};

export const useUserDomain = (userId) => {
  let targetUserId = userId;

  if (!userId) {
    targetUserId = getCurrentUserId();
  }

  const info = useUserInfo(targetUserId);
  return info && info.domain?.replace(/~/g, ".");
};

export const updateUserProfile = async ({
  userId,
  name,
  jobTitle,
  company,
  imageDataUrl,
}) => {
  if (!userId) userId = Firebase.auth().currentUser.uid;

  console.debug(
    "updateUserProfile",
    userId,
    name,
    jobTitle,
    company,
    imageDataUrl?.length
  );

  // if imageDataUrl provided, then upload it first
  let url = null;
  if (imageDataUrl) {
    try {
      url = await uploadUserImage({ imageDataUrl, userId });
    } catch (err) {
      console.error(err);
    }
  }

  return await updateDocument({
    collection: COLLECTIONS.USER_INFO,
    doc: userId,
    data: {
      [KEYS.NAME]: name,
      ...(jobTitle && { [KEYS.JOB_TITLE]: jobTitle }),
      ...(company && { [KEYS.COMPANY]: company }),
      ...(url && { [KEYS.IMAGE]: url }),
    },
  }).then((id) => {
    console.debug("updateUserProfile OK", id);
  });
};

/**
 * User project settings
 */

const getUserSubcollection = (subcollection) => {
  const userId = getCurrentUserId();
  return userId && `${COLLECTIONS.USER_INFO}/${userId}/${subcollection}`;
};

// path: /users/{userId}/settings/projects
const useUserProjectSettings = () =>
  useDocument({
    collection: getUserSubcollection(SUBCOLLECTIONS.SETTINGS),
    doc: DOCUMENTS.PROJECT,
    path: "user/settings/projects",
  });

// path: /users/{userId}/settings/organisations
const useUserOrganisationSettings = () =>
  useDocument({
    collection: getUserSubcollection(SUBCOLLECTIONS.SETTINGS),
    doc: DOCUMENTS.ORGANISATIONS,
    path: "user/settings/organisations",
  });

// path: /users/{userId}/settings/projects
const updateUserProjectSettings = ({ data, tag }) => {
  const collection = getUserSubcollection(SUBCOLLECTIONS.SETTINGS);
  const doc = DOCUMENTS.PROJECT;
  console.debug(tag, data);
  return updateDocument({
    collection,
    doc,
    data,
  }).then(() => {
    console.debug(`${tag} OK`);
  });
};

// use pinned state of a single project
// return true or false
export const useProjectPinned = (projectId) => {
  const settings = useUserProjectSettings();
  // undefined = loading
  if (isUndefined(settings)) return settings;
  return settings && KEYS.PINNED_PROJECTS in settings
    ? settings[KEYS.PINNED_PROJECTS].includes(projectId)
    : false;
};

export const useProjectUsersLastUpdate = () => {
  const settings = useUserProjectSettings();
  // undefined = loading
  if (isUndefined(settings)) return settings;
  return settings && KEYS.USERS_LAST_UPDATE in settings
    ? settings[KEYS.USERS_LAST_UPDATE]
    : null;
};

export const useOrganisationUsersLastUpdate = () => {
  const settings = useUserOrganisationSettings();
  // undefined = loading
  if (isUndefined(settings)) return settings;
  return settings && KEYS.USERS_LAST_UPDATE in settings
    ? settings[KEYS.USERS_LAST_UPDATE]
    : null;
};

export const pinProject = ({ projectId, pin }) => {
  const tag = "pinProject";
  const data = {
    [KEYS.PINNED_PROJECTS]: pin
      ? FieldValue.arrayUnion(projectId)
      : FieldValue.arrayRemove(projectId),
  };
  return updateUserProjectSettings({ data, tag });
};

/**
 * News settings
 */

// path: /users/{userId}/settings/news
export const updateUserNewsCategories = (categories) => {
  const collection = getUserSubcollection(SUBCOLLECTIONS.SETTINGS);
  const doc = DOCUMENTS.NEWS;
  const data = {
    [KEYS.NEWS_CATEGORIES]: categories,
  };
  console.debug("updateUserNewsCategories", data);
  return updateDocument({
    collection,
    doc,
    data,
  }).then(() => {
    console.debug("updateUserNewsCategories OK");
  });
};

export const updateUserNewsLastRead = () => {
  const collection = getUserSubcollection(SUBCOLLECTIONS.SETTINGS);
  const doc = DOCUMENTS.NEWS;
  const data = {
    [KEYS.NEWS_LAST_READ]: Date.now(),
  };
  console.debug("updateUserNewsLastRead", data);
  return updateDocument({
    collection,
    doc,
    data,
  }).then(() => {
    console.debug("updateUserNewsLastRead OK");
  });
};

const useUserNews = () =>
  useDocument({
    collection: getUserSubcollection(SUBCOLLECTIONS.SETTINGS),
    doc: DOCUMENTS.NEWS,
    path: "user/settings/news",
  });

export const useUserNewsCategories = () => {
  const news = useUserNews();
  if (isLoading(news)) return news;
  if (!news || !(KEYS.NEWS_CATEGORIES in news))
    return getDefaultCategoriesSettings();
  return news[KEYS.NEWS_CATEGORIES];
};

export const useUserNewsLastRead = () => {
  const news = useUserNews();
  if (isLoading(news)) return news;
  if (!news || !(KEYS.NEWS_LAST_READ in news)) return null;
  return news[KEYS.NEWS_LAST_READ];
};

export const useUserDisabledAllNews = () => {
  const categories = useUserNewsCategories();
  if (!categories) return false;
  return Object.values(categories).every((c) => c === false);
};

/**
 * admins
 */

export const useSystemAdmin = (targetUserId) => {
  const userId = targetUserId || getCurrentUserId();
  const info = useDocument({
    collection: COLLECTIONS.ADMINS,
    doc: userId,
    path: `${COLLECTIONS.ADMINS}_${userId}`,
  })
  if (!info) return info;
  return info.isAdmin;
}

export const isSystemAdmin = () => {
  const userId = getCurrentUserId();

  if (!userId) return false;

  return getDocument({ collection: COLLECTIONS.ADMINS, doc: userId })
    .then((res) => !!res?.isAdmin)
    .catch(() => false);
};

export const selectOrganisation = async ({ organisationId }) => {
  const path = API_PATHS.USER_ORGANISATION_SETTINGS_UPDATE;
  const data = { currentSelected: organisationId };
  return await postPrivateRequest({ path, data });
};

export const useUserSettingsOrganisations = () => {
  const res = useDocument({
    collection: getUserSubcollection(SUBCOLLECTIONS.SETTINGS),
    doc: DOCUMENTS.ORGANISATIONS,
    path: "user/settings/organisations",
  });

  return res;
};

export const useCurrentOrganisation = () => {
  const settings = useUserSettingsOrganisations();

  if (isUndefined(settings)) return settings;
  if (typeof settings?.currentSelected === "undefined") return null;

  return settings.currentSelected;
};

export const getCurrentOrganisation = async () => {
  const res = await getDocument({
    collection: getUserSubcollection(SUBCOLLECTIONS.SETTINGS),
    doc: DOCUMENTS.ORGANISATIONS,
  });

  if (isUndefined(res)) return res;
  if (typeof res?.currentSelected === "undefined") return null;

  return res.currentSelected;
};

export const useMostRecentOrganisationsIds = () => {
  const settings = useUserSettingsOrganisations();

  if (isUndefined(settings)) return settings;
  if (typeof settings?.mostRecent === "undefined") return [];

  return settings?.mostRecent;
};
